const Collapse = (function () {

  const selectors = {
    link: 'data-collapse'
  }

  const classes = {
    collapsed: 'collapsed'
  }


  const init = () => {
    const $links = document.querySelectorAll(`[${selectors.link}]`);

    [...$links].map( $link => {
      $link.addEventListener('click', e => {
        e.preventDefault();

        const $target = document.getElementById( e.target.dataset.collapse );
        if( $target ) {
          $target.classList.toggle( classes.collapsed );
        }
      });
    });

  }

  return {
    init: init
  }
})();

export default Collapse;