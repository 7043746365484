import customSelect from 'custom-select';
import IMask from 'imask';
import axios from 'axios';
const serialize = require('form-serialize');

const Form = (function () {

  const attributes = {
    form: 'data-form',
    next: 'data-form-next',
    prev: 'data-form-prev',
    step: 'data-form-step',
    send: 'data-form-send',
    field: 'data-form-field',
    fieldError: 'data-form-field-error'
  }

  const classes = {
    active: 'active',
    invalid: 'invalid',
    sending: 'sending'
  }

  const api = {
    contact: '/kontakt'
  }

  const fieldsPre = 'fi-';

  const fields = {
    '1': [ 'region', 'name', 'phone', 'email' ],
    '2': [ 'message', 'phone_contact', 'email_contact' ]
  }


  /* ------- */

  const $form = document.querySelector(`[${attributes.form}]`);
  let regionCustomSelect;

  const $header = document.getElementById( 'header' );

  /* ------- */

  const current = {
    step: 1
  }

  const updateStepsActive = () => {
    const stepActive = document.querySelector( `[${attributes.step}].active` );
    const stepCurrent = document.querySelector( `[${attributes.step}="${current.step}"]` );

    if ( stepActive ) {
      stepActive.classList.remove( classes.active );
    }
    if ( stepCurrent ) {
      stepCurrent.classList.add( classes.active );
    }

    if( window.outerWidth < 1024 ) {   // on mobile widths
      const headerHeight = $header.offsetHeight || 0;
      const formTop = $form.getBoundingClientRect().top + window.scrollY;

      if( !formTop || !headerHeight ) {
        return;
      }

      const targetTopOffset = formTop - headerHeight - 20;

      setTimeout( () => {
        window.scroll({
          top: targetTopOffset,    // scroll to step top
          left: 0, 
          behavior: 'smooth'
        });
      }, 500);
    }
  }

  const goToNextStep = ( doValidate = true ) => {
    if( doValidate && !validate() ) {
      return;
    }

    current.step++;
    updateStepsActive();
  }

  const goToPrevStep = ( doValidate = true ) => {
    if( doValidate && !validate() ) {
      return;
    }
    current.step--;
    updateStepsActive();
  }

  const validate = () => {
    const data = getFormData( true );
    clearErrors();

    let ret = true;

    if( parseInt(current.step) === 1 ) {
      if( !parseInt(data.region) ) {
        displayError( 'region', 'Pole nie może być puste.' );
        ret = false;
      }
      if( !data.name ) {
        displayError( 'name', 'Pole nie może być puste.' );
        ret = false;
      }
      if( !data.phone && !data.email ) {
        displayError( 'phone', 'Proszę podać adres e-mail lub nr telefonu.' );
        displayError( 'email', 'Proszę podać adres e-mail lub nr telefonu.' );
        ret = false;
      }
    }

    if( parseInt(current.step) === 1 ) {
      if( !parseInt(data.region) ) {
        displayError( 'region', 'Pole nie może być puste.' );
        ret = false;
      }
      if( !data.name ) {
        displayError( 'name', 'Pole nie może być puste.' );
        ret = false;
      }
    }

    return ret;
  }

  const send = () => {

    clearErrors();
    setFormSending(true);
    axios({
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          data: getFormData(),
          url: api.contact,
      })
      .then(function (response) {
        goToNextStep();

        setTimeout( goHome, 7000 );
      })
      .catch(function (error) {
        const response = error.response;
        if( !response || !response.data || !response.data.errors ) {
          console.warn('Unknown error: ', error);
          return;
        }

        onSendError( response );
      })
      .then(function () {
        // always executed
        setFormSending(false);
      });
    ;
  }

  const goHome = () => {
    window.location.href = '/';
  }

  const onSendError = response => {
    const errors = response.data.errors;

    Object.keys(errors).map( (k) => {   // display all errors
      if( Array.isArray(errors[k]) ) {
        errors[k].map( error => {
          displayError(k, error);
        } )
      }
    });

    if( Object.keys(errors).filter( n => fields[ '1' ].indexOf(n) !== -1 ).length ) {  // any error for step 1
      goToPrevStep( false );
    }
  }

  const getFormData = ( asObject = false ) => {
    const data = serialize($form, { hash: asObject });

    return data;
  }

  const displayError = ( field, error ) => {
    let $field = document.getElementById(`${fieldsPre}${field}`);
    if( !$field ) { return; }

    $field = $field.closest(`[${attributes.field}]`);
    $field.querySelector(`[${attributes.fieldError}]`).innerHTML = error;
    $field.classList.add( classes.invalid );
  }

  const clearError = fld => {
    let $field = document.getElementById(`${fieldsPre}${fld}`);
    if( !$field ) { return; }

    $field = $field.closest(`[${attributes.field}]`);
    $field.querySelector(`[${attributes.fieldError}]`).innerHTML = '';
    $field.classList.remove( classes.invalid );
  }

  const clearErrors = () => {
    fields['1'].concat( fields['2'] ).map( fld => {
      clearError( fld );
    });
  }

  const setFormSending = state => {
    state ? $form.classList.add( classes.sending ) : $form.classList.remove( classes.sending );
  }

  const attachButtonsEvents = () => {

    [...document.querySelectorAll( `[${attributes.next}]` )].map( elem => {
      elem.addEventListener('click', e => {
        goToNextStep();
      });
    });

    [...document.querySelectorAll( `[${attributes.prev}]` )].map( elem => {
      elem.addEventListener('click', e => {
        goToPrevStep();
      });
    });
  
    document.querySelector( `[${attributes.send}]` )
      .addEventListener('click', send);
  }

  const attachInputsEvents = () => {
    [...$form.querySelectorAll('input[type="text"], textarea, select')]
      .map( elem => {
          elem.addEventListener('input', e => {
            clearError( e.target.name );
          });
          elem.addEventListener('change', e => {
            clearError( e.target.name );
          });
        }
      )
  }

  const init = () => {
    regionCustomSelect = customSelect('[data-form] select')[0];

    attachButtonsEvents();
    attachInputsEvents();
  }

  const getRegionCustomSelect = () => { return regionCustomSelect; };

  return {
    init: init,
    getRegionCustomSelect: getRegionCustomSelect
  }
})();

export default Form;
