import Cookies from 'js-cookie';

const CookiesPanel = (function () {

  const selectors = {
    panel: 'data-cookies-panel',
    button: 'data-cookies-panel-accept'
  }

  const cookieName = 'cookie_policy_FUSO';
  const hiddenClass = 'hidden';

  const init = () => {
    const $panel = document.querySelector(`[${selectors.panel}]`);

    if ( $panel ) {
      
      if ( Cookies.get(cookieName) ) {
        $panel.classList.add( hiddenClass );

      } else {
        $panel.classList.remove( hiddenClass );
        const $button = $panel.querySelector(`[${selectors.button}]`);
        $button.addEventListener('click', () => {
          $panel.classList.add( hiddenClass );
          Cookies.set(cookieName, true);
        })
      }
    }
  }

  return {
    init: init
  }
})();

export default CookiesPanel;