const TriggerOpen = {
  
  init: () => {
    const $triggers = document.querySelectorAll('[data-toggle-open]');
    [...$triggers].map( $trigger => {
      const targetId = $trigger.dataset.toggleOpen;
      const target = document.getElementById( targetId );
      const $triggersForThis = document.querySelectorAll(`[data-toggle-open="${targetId}"]`);
      const section = $triggersForThis[0].closest('section');

      $trigger.addEventListener('click', e => {
        e.preventDefault();

        const headerHeight = document.getElementById('header').offsetHeight;
        const wasOpen = target.classList.contains('open');
        const targetTopOffset = target.offsetTop - headerHeight;
        const sectionTopOffset = section.offsetTop - headerHeight;

        if( target && targetTopOffset ) {
          target.classList.toggle('open');

          [...$triggersForThis].map( $trigger => { 
            $trigger.classList.toggle('disabled') 
          } );

          setTimeout( () => {
            window.scrollTo({
              top: wasOpen ? sectionTopOffset : targetTopOffset,    // scroll to details or section
              left: 0, 
              behavior: 'smooth'
            });
          }, 350);
        }
      });
    } )
  }
}

export default {
  init: TriggerOpen.init
}