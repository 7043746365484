const init = function() {
  const triggersOpen = document.querySelectorAll('[data-modal-open]');
  const triggersClose = document.querySelectorAll('[data-modal-close]');

  const open = selector => {
    const modal = document.querySelector(selector);
    if (modal) {
      modal.classList.add('open');
      document.documentElement.classList.add('noscroll');
    }
  };

  const close = () => {
    const modal = document.querySelector('.modal.open');
    if (modal) {
      modal.classList.remove('open');
      document.documentElement.classList.remove('noscroll');
    }
  };

  [...triggersOpen].map(trigger => {
    trigger.addEventListener('click', e => {
      e.preventDefault();
      open(trigger.dataset.modalOpen);
    });
  });

  [...triggersClose].map(trigger => {
    trigger.addEventListener('click', e => {
      e.preventDefault();
      close();
    });
  });

  document.querySelector('.modal__inner').addEventListener('click', e => {
    e.stopPropagation();
  });

  document.onkeydown = function(evt) {
    evt = evt || window.event;
    if (evt.keyCode === 27) {
      close();
    }
  };
};

export default { init };
