import $ from 'jquery';
import Form from './form';

var locations = [
  ['<strong>Auto Idea Sp. z o.o.</strong>', 53.147871, 23.080294, 'ul. Narodowych Sił Zbrojnych 9,<br>15-690 Białystok'],
  ['<strong>Auto Idea Sp. z o.o.</strong>', 53.781921, 20.514973, 'ul. Towarowa 11,<br>10-416 Olsztyn'],
  ['<strong>Auto Partner, Jan Garcarek i Andrzej Garcarek Sp. j.</strong>', 51.697614, 17.946282, 'ul. Ostrowska 6,<br>63-460 Nowe Skalmierzyce'],
  ['<strong>Kujawski Truck Center Sp. z o.o.</strong>', 53.079763, 18.768037, 'Rogówko 114B,<br>87-162 Lubicz'],
  ['<strong>Mercedes-Benz Sosnowiec Sp. z o.o.</strong>', 50.32427, 19.114444, 'ul. Czeladzka 67,<br>42-500 Będzin'],
  ['<strong>Mercedes-Benz Warszawa Sp. z o.o.</strong>', 52.436098, 21.236598, 'ul. Gottlieba Daimlera 4 ,<br>05-250 Radzymin'],
  ['<strong>Mirosław Wróbel Sp. z o.o.</strong>', 54.277574, 18.589344, 'ul. Starogardzka 24,<br>83-010 Straszyn'],
  ['<strong>Mirosław Wróbel Sp. z o.o.</strong>', 51.042453, 16.887256, 'ul. Fabryczna 32,<br>55-080 Pietrzykowice'],
  ['<strong>Mojsiuk Sp.z o.o. Sp. k.</strong>', 54.170264, 16.104609, 'ul. Koszalińska 89,<br>76-039 Stare Bielice'],
  ['<strong>Sobiesław Zasada Automotive Sp. z o.o. Sp. k.</strong>', 50.105761, 19.848159, 'ul. Profesora Adama Rożańskiego 3,<br>32-085 Modlniczka'],
  ['<strong>Sobiesław Zasada Automotive Sp. z o.o. Sp. k.</strong>', 50.02856, 22.012122, 'Al. mjr. Wacława Kopisto 3,<br>35-309 Rzeszów']
];

/* -- adopted from the previous page version */

var searchPlaces = (function () {
  var elementSubmit = '#search_submit';
  var mapDiv = document.getElementById('map');
  var geocoder, map, infowindow, bounds;
  var btnPlace = '.places-list button';
  var markers = [];
  var foundZoom = 12;
  var onlyBoundingMarkers = false;
  var searchGeocodedLocation;
  var closest;
  var skipFindingClosest = false;

  var init = function () {
      if ($(mapDiv).length == 0) {
          return true;
      }
      var image = {
          url: '/resources/images/pin.svg',
      };

      map = new google.maps.Map(mapDiv, {
          center: {lat: 52.0865117, lng: 19.3511812},
          zoom: 6,
          optimized: false,
          icon: image,
          draggable: !("ontouchend" in document)
      });

      geocoder = new google.maps.Geocoder();
      infowindow = new google.maps.InfoWindow();

      var marker, i;

      for (i = 0; i < locations.length; i++) {
          marker = new google.maps.Marker({
              position: new google.maps.LatLng(locations[i][1], locations[i][2]),
              map: map,
              icon: image,
          });

          google.maps.event.addListener(marker, 'click', (function (marker, i) {
              return function () {
                  showInfo(marker, i);
                  const v = i + 1;
                  $('#fi-region option[value=' + v + ']').prop('selected', true).change();
                  Form.getRegionCustomSelect().value = v;
              }
          })(marker, i));

          markers.push(marker);
      }

      submitListener();
      placesClickListener();
      select1ClickListener();
  };


  var showInfo = function (marker, i) {
      infowindow.setContent(locations[i][0] + '<br>' + locations[i][3]);
      infowindow.open(map, marker);
  };


  var placesClickListener = function () {
      $(btnPlace).click(function (e) {
          e.preventDefault();
          var i = $(this).data().id;
          map.panTo(markers[i].getPosition());
          zoomTo(foundZoom);
          showInfo(markers[i], i);
      });
      $("#fi-region").change(function () {
          var i = $("#fi-region option:selected").val() - 1;
          map.panTo(markers[i].getPosition());
          zoomTo(foundZoom);
          showInfo(markers[i], i);
      });
  };

  var select1ClickListener = function () {
      $("#fi-region").click(function () {
          map.setCenter({lat: 52.0865117, lng: 19.3511812});
          zoomTo(6);
      });
  }

  var checkMarkersInBounds = function () {
      var foundNum = 0;

      for (var i = 0; i < markers.length; i++) {
          if (map.getBounds().contains(markers[i].getPosition())) {
              foundNum++;
          } else {
              onlyBoundingMarkers = true;
              var btn = btnPlace + '[data-id=' + i + ']';
              $(btn).slideUp();
          }
      }

      if (skipFindingClosest == false && foundNum == 0) {
          findClosestMarker();
      }

      skipFindingClosest = false;
  };


  var findClosestMarker = function () {
      $.each(markers, function () {
          var distance = google.maps.geometry.spherical.computeDistanceBetween(this.getPosition(), searchGeocodedLocation);

          if (!closest || closest.distance > distance) {
              closest = {
                  marker: this,
                  distance: distance
              }
          }
      });

      if (closest) {
          bounds = new google.maps.LatLngBounds();
          bounds.extend(searchGeocodedLocation);
          bounds.extend(closest.marker.position);
          map.fitBounds(bounds);

          resetBoundingMarkersList();
          skipFindingClosest = true;
          checkMarkersInBounds();
      }
  };


  var submitListener = function () {
      $(elementSubmit).on('click', function (e) {
          e.preventDefault();

          resetBoundingMarkersList();
          resetAllButtons();
          geocodeAddress(geocoder, map);
      });
  };


  var resetAllButtons = function () {
      $(btnPlace).removeClass('active');
  };


  var resetBoundingMarkersList = function () {
      $(btnPlace).slideDown();
  };


  var geocodeAddress = function (geocoder, resultsMap) {
      var address = document.getElementById('address').value;

      geocoder.geocode({'address': address}, function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
              searchGeocodedLocation = results[0].geometry.location;
              resultsMap.setCenter(searchGeocodedLocation);

              zoomTo(foundZoom);
              checkMarkersInBounds();
          } else {
              // console.warn('Nie rozpoznano lokalizacji')
          }
      });
  };


  var zoomTo = function (targetZoom) {
      map.setZoom(targetZoom);
  };


  return {
      init: init
  }
})();


window.initMap = searchPlaces.init;
