import { tns as TinySlider } from 'tiny-slider/src/tiny-slider.module';
import * as utils from './utils';

const HeroSlider = {
  init: () => {
    const jumperItems = document.querySelectorAll('[data-hero-jumper-index]');
    const sliderItems = document.querySelectorAll('[data-hero-slider]');

    if( !sliderItems.length ) {
      return;
    }

    // https://github.com/ganlanyuan/tiny-slider
    const slider = TinySlider({
      container: '[data-hero-slider]',
      items: 1,
      slideBy: 'page',
      mode: utils.hasMobileWidth() ? 'carousel' : 'gallery',
      speed: 300,
      controls: false,
      nav: true,          // dots
      autoplayButtonOutput: false,
      mouseDrag: false,
      autoplay: true,
      autoplayTimeout: 5000,
      autoplayHoverPause: true,
      arrowKeys: true

      // animateIn: 'tns-fadeIn',
      // animateOut: 'tns-fadeOut',
      // animateNormal: ''
    });

    slider.events.on('indexChanged', (info, eventName) => {
      const index = info.index > jumperItems.length-1 ? 1 : info.index + 1;
      const jumper = document.querySelector(`[data-hero-jumper-index="${index}"]`);

      [...jumperItems].map( elem => {
        elem.classList.remove('active');
      });
      
      if ( jumper ) {
        jumper.classList.add('active');
      }
    });

    [...jumperItems].map( (jumperItem, i) => {
      jumperItem.addEventListener('click', (e, elem) => {
        slider.goTo(i);
      });
    });
  }
}

export default {
  init: HeroSlider.init
}