const Header = {
  resizeHeaderOnScroll: () => {
    const 
      shrinkedClass = 'header--shrinked',
      distanceY = window.pageYOffset || document.documentElement.scrollTop,
      shrinkOn = 150,
      headerEl = document.getElementById('header');
    
    if (distanceY > shrinkOn) {
      headerEl.classList.add( shrinkedClass );
    } else {
      headerEl.classList.remove( shrinkedClass );
    }
  },
  
  init: () => {
    window.addEventListener('scroll', Header.resizeHeaderOnScroll);
  }
}

export default {
  init: Header.init
}