import '@babel/polyfill';
import smoothscroll from 'smoothscroll-polyfill';
import './polyfills';

import './modules/map';
import Preloader from './modules/preloader';
import Header from './modules/header';
import HeroSlider from './modules/hero-slider';
import TriggerOpen from './modules/trigger-open';
import Form from './modules/form';
import Collapse from './modules/collapse';
import CookiesPanel from './modules/cookies-panel';
import Modal from './modules/modal';

window.addEventListener('load', () => {
  Preloader.hide();
  smoothscroll.polyfill();

  CookiesPanel.init();
  Header.init();
  HeroSlider.init();
  TriggerOpen.init();
  Form.init();
  Collapse.init();
  Modal.init();
});
